import React, { useState } from 'react';
import Slider from "react-slick";
import '../styles/SplashPage.css'

import img1 from '../running.jpg'
import img2 from '../swimming.jpg'
import img3 from '../cycling.jpg'
import colorPalette from '../utils/colors';

import * as Api from '../network/api'

const SplashPage: React.FC = () => {

    const [emailInput, setEmailInput] = useState('');
    const [subscribed, setSubscribed] = useState(false);

    async function subscribe(){
        const credentials:Api.SubscribeCredentials = {email:emailInput};

        await Api.subscribe(credentials)
        setSubscribed(true);
    }

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            overflowY: 'scroll',
            scrollSnapType: 'y mandatory'
          }}>
            
            {/* FIRST VIEW */}
            <div style={{
              display: 'flex',
              width: '100%',
              backgroundColor:'#f3f3f3',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
              scrollSnapAlign: 'start'
            }}>
              <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                backgroundColor: '#f3f3f3',
                flex: 1,
                overflow: 'visible',
                zIndex: 100
              }}>
                <div className="content-image" style={{
                  display: 'flex',
                  width: '25vw',
                  height: '22vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#d1d1d1',
                  position: 'absolute',
                  marginLeft: '5vw',
                  marginTop: '5vw'
                }}>
                  <img alt='' src={img1} width='100%' height='100%' style={{ zIndex: 1, objectFit: 'cover' }} />
                </div>
                <div className="content-image" style={{
                  display: 'flex',
                  width: '25vw',
                  height: '22vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#b1b1b1',
                  position: 'absolute',
                  top: '13vw',
                  left: '15vw',
                  marginLeft: '5vw',
                  marginTop: '5vw'
                }}>
                  <img alt='' src={img3} width='100%' height='100%' style={{ zIndex: 2, objectFit: 'cover' }} />
                </div>
                <div className="content-image" style={{
                  display: 'flex',
                  width: '25vw',
                  height: '22vw',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: '#f1f1f1',

                  position: 'absolute',
                  top: '22vw',
                  left: '28vw',
                  marginLeft: '5vw',
                  marginTop: '5vw'
                }}>
                  <img alt='' src={img2} width='100%' height='100%' style={{ zIndex: 3, objectFit: 'cover' }} />
                </div>
              </div>
              <div 
                className='title-div'
                style={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    marginBottom: '25vh',
                    marginLeft: '10vw',
                    background: 'linear-gradient(to right, #f3f3f3, #ffffff)',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    position: 'relative'
                }}
                >
                <label className='label-title' style={{
                    fontSize: '6vw',
                    fontStyle:'italic',
                    lineHeight:1,
                    padding:0,
                    margin:0
                }}>VITOVA</label>
                <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2.5vw',
                    lineHeight:1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding:0,
                    margin:0
                }}>Adaptive Training</label>
                <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    padding:'20px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                }}>Here to make <b>health</b> accessible. <b>Evidence based</b>, medically assured <b>adaptive</b> training plans for your health, fitness and wellbeing goals.
                </p>
                {/* <label 
                className='read-more-button'
                style={{
                    fontSize: '1em',
                    color: '#f1f1f1',
                    textAlign: 'center',
                    lineHeight:1,
                    width: 'auto',
                    margin:0,
                    padding:'10px 20px',
                    backgroundColor:'steelblue'
                }}>
                    Learn More About Us
                </label> */}
                {/* <label 
                style={{
                    fontSize: '1em',
                    color: '#454545',
                    textAlign: 'center',
                    lineHeight:1,
                    width: 'auto',
                    margin:0,
                    padding:'10px 20px',
                }}>
                    Or
                </label> */}
                <div className='subscribe-div' style={{backgroundColor:'white', display:'flex', width:'auto', zIndex:100,boxShadow:'2px 2px 2px 1px rgb(0 0 0 / 20%)', borderRadius:'5px',border:'1px solid lightgray',padding:'10px 0px 20px 0px',justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                <label 
                style={{
                    fontSize: '1em',
                    color: '#454545',
                    textAlign: 'center',
                    lineHeight:1,
                    width: 'auto',
                    margin:0,
                    padding:'10px 20px',
                }}>
                    Join our waiting list for news and early access opportunities!
                </label>
                <div style={{display:'flex', flex:1, flexDirection:'row', maxHeight:'5vh'}}>
                    {!subscribed && 
                    <input className='email-input' value={emailInput} onChange={(e)=>setEmailInput(e.currentTarget.value)} style={{padding:'8px 5px'}} type='email' placeholder='example@gmail.com'></input>
                    
                    }
                    <label 
                    onClick={()=>subscribe()}
                    className='subscribe-button'
                    style={{
                        pointerEvents:subscribed ? 'none':'all',
                        fontSize: '1em',
                        textAlign: 'center',
                        width: 'auto',
                        margin:0,
                    }}>
                        {subscribed  ? `Subscribed!` : `Subscribe`}
                    </label>
                </div>
                </div>


                </div>

              
            </div>
            {/* MEET THE TEAM */}
            {/* <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '40%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '20vh',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to right, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>The Team</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    margin: 0
                    }}>Meet Our Members</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    padding: '20px',
                    textAlign: 'justify',
                    width: '80%' // Adjust width as needed
                    }}>
                    At <b>VITOVA</b>, our expert team combines cutting-edge technology, health expertise, and athletic coaching. Our technical developers design innovative solutions, our <b>medical professionals</b> provide comprehensive care, and our professional coaches enhance performance with tailored training. Together, we deliver a <b>holistic</b> approach to excellence in health and fitness.
                    </p>
                    <label 
                                style={{
                                    fontSize: '1em',
                                    color: '#454545',
                                    textAlign: 'center',
                                    lineHeight:1,
                                    width: 'auto',
                                    margin:0,
                                    padding:'10px 20px',
                                }}>
                                    Interested in a career at VITOVA?</label>
                    <label 
                    className='read-more-button'
                    style={{
                        fontSize: '1em',
                        color: '#f1f1f1',
                        textAlign: 'center',
                        lineHeight: 1,
                        width: 'auto',
                        padding: '10px 20px',
                        backgroundColor: 'steelblue',
                        cursor: 'pointer' // Added cursor for better UX
                    }}
                    >
                    Explore Open Positions
                    </label>
                </div>

                <div style={{
                    display: 'flex',
                    width: '60%', // Adjusted to share space with title-div
                    height: '100%',
                    // background: 'linear-gradient(to right, #f3f3f3, #ffffff)',
                    flexDirection: 'column', // Ensure vertical stacking within slider container
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom:'5vh',
                    paddingRight:'5vw',
                    overflow: 'visible',
                    zIndex: 100
                }}>
                    <div className="meet-the-team-container" style={{  width: '100%' }}>
                    <Slider {...settings}>
                        {teamMembers.map((member, index) => (
                        <div key={index} className="team-member" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <img src={member.photo} alt={member.name} style={{ width: '20vw', height: '20vw', objectFit: 'cover', borderRadius: '50%' }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '3vh' }}>
                            <label style={{ fontSize: '2vw', fontWeight: 'bold', paddingRight: 0 }}>{member.name}</label>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SocialIcon bgColor='transparent' fgColor='steelBlue' style={{ width: '3vw', height: '3vw' }} url={`https://www.instagram.com/${member.instagram}/`} />
                                <label style={{ fontSize: '1.5vw', margin: '0 6px' }}>|</label>
                                <label style={{ fontSize: '1.5vw' }}>{member.role}</label>
                            </div>
                            </div>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '1vh' }}>
                            <label style={{ fontSize: '1.1vw', textAlign: 'center', maxWidth: '50%' }}>{member.description}</label>
                            </div>
                        </div>
                        ))}
                    </Slider>
                    </div>
                </div>              
            </div> */}
            {/* OUR STORY */}
            {/* <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '100%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '20vh',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to left, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>Our Story</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'8px',

                    }}>The journey and vision</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    padding: '20px',
                    textAlign: 'justify',
                    width: '80%' // Adjust width as needed
                    }}>
                    <b>VITOVA</b> has been built around the idea that a smart, <b>medically informed</b> approach to training should be available to all. Allowing you to have <b>confidence</b> in the sessions that you’re doing, and lead a healthy active lifestyle in the process. Our plans are built around the <b>latest evidence</b> to ensure that you can make improvements both in your fitness, and the control of any relevant medical conditions.
                    </p>
                    <label 
                        style={{
                            fontSize: '1em',
                            color: '#454545',
                            textAlign: 'center',
                            lineHeight:1,
                            width: 'auto',
                            margin:0,
                            padding:'10px 20px',
                        }}>
                            Interested in exploring investment possibilities?</label>
                    <label 
                    className='read-more-button'
                    style={{
                        fontSize: '1em',
                        color: '#f1f1f1',
                        textAlign: 'center',
                        lineHeight: 1,
                        width: 'auto',
                        padding: '10px 20px',
                        backgroundColor: 'steelblue',
                        cursor: 'pointer' // Added cursor for better UX
                    }}
                    >
                    Contact Our Team
                    </label>
                </div>             
            </div> */}
        </div>
          

    );
}

export default SplashPage;
