// AboutPage.tsx
import React, { useState } from 'react';
import '../styles/BlogPage.css';
import { Article } from '../models/article';
import colorPalette from '../utils/colors';

const BlogPage: React.FC = () => {

    const [articles, setArticles] = useState<Array<Article>>(
        [
            {
                _id:'0',
                title:'Our Story',
                description:'',
                author:'Nicholas Latham, Jake Keast',
                date:new Date(),
                file:null,


            }
        ]);

    return (
        <div style={{
            display: 'flex',
            width: '100%',
            height: 'auto',
            flexDirection: 'column',
            overflowY: 'scroll',
            scrollSnapType: 'y mandatory'
          }}>            
            {/* OUR BLOGS */}
            <div style={{
                display: 'flex',
                width: '100%',
                backgroundColor: '#f3f3f3',
                height: '80vh',
                justifyContent: 'center',
                alignItems: 'center',
                scrollSnapAlign: 'start'
                }}>
                <div 
                    className='title-div'
                    style={{
                    display: 'flex',
                    width: '100%', // Adjusted to share space with slider
                    height: '100%',
                    marginBottom: '0',
                    marginRight: '0', // Adjusted for better spacing
                    background: 'linear-gradient(to left, #ffffff, #f3f3f3)',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                    }}
                >
                    <label className='label-title' style={{
                    fontSize: '4vw',
                    fontStyle: 'italic',
                    lineHeight: 1,
                    padding: 0,
                    margin: 0
                    }}>VITOVA Blog</label>
                    <label className='label-title' style={{
                    fontWeight: 'normal',
                    fontStyle: 'italic',
                    fontSize: '2vw',
                    lineHeight: 1,
                    color: `${colorPalette.steelBlue}aa`,
                    padding: 0,
                    marginTop:'8px',

                    }}>Our Evidence Based Research</label>
                    <p style={{
                    fontSize: '1em',
                    color: '#454545',
                    padding: '20px',
                    textAlign: 'center',
                    width: '80%' // Adjust width as needed
                    }}>
                    <b>VITOVA</b> is an <b>evidence based </b> platform.
                    </p>
                </div>             
            </div>
        </div>

            
    );
}

export default BlogPage;
