import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faChevronDown, faSearch, faSignIn } from '@fortawesome/free-solid-svg-icons';
import profileImage from '../svg-icons/profile.svg'; // Import the profile image
import '../styles/Header.css';
import { User } from '../models/user';
import * as Api from "../network/api";
import colorPalette from '../utils/colors';


interface HeaderProps {
    user: User | null,
    onLogoutSuccessful: () => void,
}

const Header = ({user, onLogoutSuccessful}: HeaderProps) => {
    const [dropdowns, setDropdowns] = useState({
        plans: false,
        more: false,
        profile: false,
    });

    const handleMouseEnter = (menu: string) => {
        setDropdowns(prevState => ({
            ...prevState,
            [menu]: true,
        }));
    };

    const handleMouseLeave = (menu: string) => {
        setDropdowns(prevState => ({
            ...prevState,
            [menu]: false,
        }));
    };

    const closeAllDropdowns = () => {
        setDropdowns({
            plans: false,
            more: false,
            profile: false,
        });
    };

    async function logout() {
        closeAllDropdowns()
        try {
            await Api.logout();
            onLogoutSuccessful();
        } catch (error) {
            console.error(error);
            alert(error);
        }
    }

    return (
        <header className="header">
            <div className="left-section">

                <Link className='home-logo' to="/" style={{fontStyle:'italic'}}>VITOVA</Link>
                {/* <FontAwesomeIcon className='dropdown-icon' style={{width:'20px', height:'20px', marginRight:'10px', marginLeft:'8px'}} icon={faSearch} /> */}

            </div>
            <nav className="nav">
                <ul>
                    {user && user.info &&
                        <li className="nav-item">
                            <Link to={user.info ? "/dashboard" : "/home"}>Dashboard</Link>
                        </li>
                    }
                    {user && user.info &&
                    <li className="nav-item">
                        <div
                            className="dropdown-trigger"
                            onMouseEnter={() => handleMouseEnter('plans')}
                            onMouseLeave={() => handleMouseLeave('plans')}
                        >
                            <Link to="/plans" style={{paddingLeft:`${dropdowns.plans ? '5px':'7px'}`}} className={`${dropdowns.plans ? `dropdown-option-active`:``}`}>Plans</Link>

                            <FontAwesomeIcon style={{color:`${dropdowns.plans ? 'steelBlue':'#555555'}`}} className='dropdown-icon' icon={faChevronDown} />
                            {dropdowns.plans && (
                                <ul className="dropdown-menu">
                                    <li><Link className='main-dropdown-option' to="/plans" onClick={closeAllDropdowns}>Obesity</Link></li>
                                    <li><Link className='main-dropdown-option' to="/plans" onClick={closeAllDropdowns}>Anxiety</Link></li>
                                    <li><Link className='main-dropdown-option' to="/plans" onClick={closeAllDropdowns}>Depression</Link></li>
                                    {/* <li><Link className='main-dropdown-option' to="#" onClick={closeAllDropdowns}>Plan Quiz</Link></li> */}
                                </ul>
                            )}
                        </div>
                    </li>
                    }
                    {/* <li className="nav-item">
                        <div
                            className="dropdown-trigger"
                            onMouseEnter={() => handleMouseEnter('more')}
                            onMouseLeave={() => handleMouseLeave('more')}
                        >
                            <Link to="/store" style={{paddingLeft:`${dropdowns.more ? '5px':'7px'}`}}  className={`${dropdowns.more ? `dropdown-option-active`:``}`}>Store</Link>

                            <FontAwesomeIcon style={{color:`${dropdowns.more ? 'steelBlue':'#555555'}`}} className='dropdown-icon' icon={faChevronDown} />
                            {dropdowns.more && (
                                <ul className="dropdown-menu">
                                    <li><Link to="/clothing" onClick={closeAllDropdowns}>Clothing</Link></li>
                                    <li><Link to="/coaching" onClick={closeAllDropdowns}>Coaching</Link></li>

                                </ul>
                            )}
                        </div>
                    </li> */}
                    {/* {!user &&
                        <li className="nav-item">
                        <Link to="/blog">Our Story</Link>
                        </li>
                    } */}
                    {!user &&
                        <li className="nav-item">
                            <Link to="/story">Our Story</Link>
                        </li>
                    }
                    {!user &&
                        <li className="nav-item">
                            <Link to="/about">Meet The Team</Link>
                        </li>
                    }
                    {!user && 
                        <li className="nav-item">
                            <Link to="/contact">Investment Opportunities</Link>
                        </li>
                    }
                    {!user && 
                        <li className="nav-item">
                            <Link to="/blog">Blog</Link>
                        </li>
                    }
                    {user && user.info &&
                    <li className="nav-item">
                        <Link to="/workoutbuilder">Workout Builder</Link>
                    </li>
                    }
                    {user && user.info &&
                    <li className="nav-item">
                        <Link to="/database">VitovaDB</Link>
                    </li>
                    }


                </ul>
            </nav>
            <div className="right-section">
                <div className="profile-menu">
                    {!user && 
                        <li className="nav-item" style={{margin:0, backgroundColor:'steelblue', color:'white',padding:'5px 10px'}}>
                            <Link to="/home">Launch App</Link>
                        </li>
                    }
                    {user && user.info &&
                        <div
                        className="dropdown-trigger"
                        onMouseEnter={() => handleMouseEnter('profile')}
                        onMouseLeave={() => handleMouseLeave('profile')}>
                        {/* <Link to={'/profile'}> */}
                        <img src={profileImage} alt="Profile" className="profile-icon" />
                        {/* </Link> */}
                        <FontAwesomeIcon className='dropdown-icon' icon={faChevronDown} />
                        {dropdowns.profile && (
                            <ul className="dropdown-menu profile-dropdown">
                                <li><Link className='dropdown-option' to="/profile" onClick={closeAllDropdowns}>Profile</Link></li>
                                <li><Link className='dropdown-option' to="/connect" onClick={closeAllDropdowns}>Connect To Strava</Link></li>
                                <li><Link className='dropdown-option' to="/form" onClick={closeAllDropdowns}>Update Medical Info</Link></li>
                                <li><Link className='dropdown-option' onClick={logout} to={'/'}>Logout</Link></li>
                            </ul>
                        )}
                    </div>
                    }

                </div>
            </div>
        </header>
    );
}

export default Header;
