import { Container } from "react-bootstrap";
import { User } from "../models/user";
import DatabasePageLoggedInView from "../components/DatabasePageLoggedInView";
import DatabasePageLoggedOutView from "../components/DatabasePageLoggedOutView";

interface DatabasePageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const DatabasePage = ({ loggedInUser, updateUser }: DatabasePageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <DatabasePageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        onPlanUpdated={(user:User)=>updateUser(user)}
                        />
                    : <DatabasePageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default DatabasePage;