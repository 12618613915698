import { Container } from "react-bootstrap";
import ConnectPageLoggedInView from "../components/ConnectPageLoggedInView";
import { User } from "../models/user";
import ConnectPageLoggedOutView from "../components/ConnectPageLoggedOutView";

interface ConnectPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const ConnectPage = ({ loggedInUser, updateUser }: ConnectPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <ConnectPageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <ConnectPageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default ConnectPage;
