import { Activity, Week } from "../models/plan";

export function getTotalActivityLength(activity:Activity){
    var total = 0;
    var stringPrefix = 'Distance'
    var stringSuffix = 'km'
    for (const step of activity.steps){
        if (step.stepDistance && step.stepDistance > 0){
            total += step.stepDistance;
            stringPrefix = 'Distance';
            stringSuffix = 'km';
        }else if (step.stepDuration && step.stepDuration > 0){
            total += step.stepDuration;
            stringPrefix = 'Duration';
            stringSuffix = ' Minutes';

        }
    }

    return `Total ${stringPrefix}: ${Math.round(total * 10) / 10}${stringSuffix}`;
}

export function getTotalWeekLength(week:Week){
    var total = 0;

    var stringPrefix = 'Distance'
    var stringSuffix = 'km'
    for (const day of week.days){
        for (const activity of day.activities){
            for (const step of activity.steps){
                if (step.stepDistance && step.stepDistance > 0){
                    total += step.stepDistance;
                    stringPrefix = 'Distance';
                    stringSuffix = 'km';
                }else if (step.stepDuration && step.stepDuration > 0){
                    total += step.stepDuration;
                    stringPrefix = 'Duration';
                    stringSuffix = ' Minutes';
        
                }
            }
        }
    }
    return `Total ${stringPrefix}: ${Math.round(total * 10) / 10}${stringSuffix}`;

}