import React, { useState, useEffect } from 'react';
import '../styles/WorkoutBuilderPage.css';
import Spinner from "./Spinner";
import { Link } from 'react-router-dom';

const WorkoutBuilderPageLoggedOutView = () => {
    const [isTimedOut, setIsTimedOut] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsTimedOut(true);
        }, 15000); // 10 seconds

        // Clean up the timer when the component is unmounted
        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="home-page" style={{display:"flex", height:'75vh', width:'100%', justifyContent:'center'}}>
            <div className={`main-content`}>
                {isTimedOut ? (
                    <div style={{ textAlign: 'center',  flexDirection:'column'}}>
                        <label style={{marginBottom:'12px', color: '#454545', fontSize:'1.5em',}}>Session timed out, please log back in</label>
                        <Link className='signup-button' to={'/'}>Back To Home</Link>
                    </div>

                ) : (
                    <Spinner text="Loading" />
                )}
            </div>
        </div>
    );
}

export default WorkoutBuilderPageLoggedOutView;
