function extractActivityStep(stringInput:string, stepName:string) {
    const activityStep = {
        stepName: stepName,
        stepType: "",
        stepDuration: -1,
        stepDistance: -1,
        stepZone: -1,
        stepIntensity: -1,
        stepReps: -1,
        stepSets: -1,
        stepExercise: '',
        stepDescription: stringInput
    };

    // Extract distance (e.g., "4km")
    const distanceMatch = stringInput.match(/(\d+(?:\.\d+)?)\s*(km|m)/);
    if (distanceMatch) {
        const distanceValue = parseFloat(distanceMatch[1]);
        const distanceUnit = distanceMatch[2].toLowerCase();
        activityStep.stepDistance = distanceUnit === 'm' ? distanceValue / 1000 : distanceValue; // Convert km to meters if necessary
    }

    // Extract intensity (e.g., "intensity 8")
    const intensityMatch = stringInput.match(/intensity\s*(\d+)/);
    if (intensityMatch) {
        activityStep.stepIntensity = parseInt(intensityMatch[1], 10);
    }

    // Extract activity type (e.g., "running")
    const activityMatch = stringInput.match(/\b(running|cycling|swimming|walking)\b/i);
    if (activityMatch) {
        activityStep.stepType = activityMatch[1].toLowerCase()
            .replace('running', 'Run')
            .replace('cycling', 'Cycle')
            .replace('swimming', 'Swim');
    }

    // Extract duration (e.g., "40 minutes" or "30 seconds")
    const durationMatch = stringInput.match(/(\d+)\s*(minutes|minute|seconds|second)/);
    if (durationMatch) {
        const durationValue = parseInt(durationMatch[1], 10);
        const durationUnit = durationMatch[2].toLowerCase();
        activityStep.stepDuration = durationUnit.includes('minute') ? durationValue * 60 : durationValue; // Convert minutes to seconds if necessary
    }

    // Extract reps (e.g., "4 reps")
    const repsMatch = stringInput.match(/(\d+)\s*reps?/);
    if (repsMatch) {
        activityStep.stepReps = parseInt(repsMatch[1], 10);
    }

    // Extract sets (e.g., "5 sets")
    const setsMatch = stringInput.match(/(\d+)\s*sets?/);
    if (setsMatch) {
        activityStep.stepSets = parseInt(setsMatch[1], 10);
    }

    // Extract zone (e.g., "zone 4")
    const zoneMatch = stringInput.match(/zone\s*(\d+)/);
    if (zoneMatch) {
        activityStep.stepZone = parseInt(zoneMatch[1], 10);
    }

    // Extract exercise (e.g., "chest press")
    const exerciseMatch = stringInput.match(/\d+\s*sets?\s*of\s*\d+\s*reps?\s*of\s*(.+)/i);
    if (exerciseMatch) {
        activityStep.stepExercise = exerciseMatch[1].trim().toLowerCase();
    }
    return activityStep;
}

export default extractActivityStep;
