import React from 'react';
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';

interface Props {
data:IExerciseData[],
  width: number;  // Percentage value for width
  height: number; // Percentage value for height
}

const BodyHighlighterDiagram: React.FC<Props> = ({data, width, height }) => {
  const handleClick = React.useCallback(({ muscle, data }: IMuscleStats) => {
    const { exercises, frequency } = data;

    alert(`You clicked the ${muscle}! You've worked out this muscle ${frequency} times through the following exercises: ${JSON.stringify(exercises)}`);
  }, []);

  return (
    <Model
      data={data}
      style={{ width: `${width}%`, height: `${height}%`, padding: '5vh' }}
      onClick={handleClick}
    />
  );
};

export default BodyHighlighterDiagram;
