import { Container } from "react-bootstrap";
import FormPageLoggedInView from "../components/FormPageLoggedInView";
import FormPageLoggedOutView from "../components/FormPageLoggedOutView";
import { User } from "../models/user";

interface FormPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const FormPage = ({  loggedInUser, updateUser  }: FormPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <FormPageLoggedInView 
                        onInfoSet={(user)=>updateUser(user)}
                        
                        user={loggedInUser}
                        />
                    : <FormPageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default FormPage;
