import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/DatabasePage.css';
import { useEffect, useState } from "react";
import Spinner from "./Spinner";
import exercises from '../localdata/exercises'; // Import the exercises list

interface DatabasePageLoggedInProps {
    user: User,
    onLogoutSuccessful: () => void,
    onPlanUpdated: (user: User) => void
}

interface Exercise {
    name: string;
    force: string;
    level: string;
    mechanic: string | null;  // Updated to allow null
    equipment: string;
    primaryMuscles: string[];
    secondaryMuscles: string[];
    instructions: string[];
    category: string;
    images: string[];
    conditionsBlackList:string[];
    id: string;
}

const DatabasePageLoggedInView = ({ user, onLogoutSuccessful, onPlanUpdated }: DatabasePageLoggedInProps) => {
    const [loading, setLoading] = useState(true);
    const [filteredExercises, setFilteredExercises] = useState<Exercise[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [forceFilter, setForceFilter] = useState<string>("");
    const [levelFilter, setLevelFilter] = useState<string>("");
    const [musclesFilter, setMusclesFilter] = useState<string>("");
    const [conditionFilter, setConditionFilter] = useState<string>("");
    useEffect(() => {
        // Simulate data loading
        setTimeout(() => {
            setLoading(false);
            setFilteredExercises(exercises as Exercise[]); // Type assertion to cast exercises as Exercise[]
        }, 1000); // Simulating a network request delay
    }, []);

    const handleSearch = () => {
        const filtered = exercises.filter((exercise) =>
            exercise.name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) &&
            (forceFilter ? exercise.force === forceFilter : true) &&
            (levelFilter ? exercise.level === levelFilter : true) &&
            (musclesFilter ? (exercise.primaryMuscles as string[]).includes(musclesFilter) : true) &&
            (conditionFilter ? (exercise.conditionsBlackList as string[]).includes(conditionFilter) : true)
          ) as Exercise[]; // Cast the filtered result as Exercise[]
        setFilteredExercises(filtered);
    };

    return (
<div className="database-page-container">
    {loading ? (
        <Spinner text="Loading Database" />
    ) : (
        <div>
            <h1 className="database-page-header">VitovaDB</h1>

            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search exercises..."
                value={searchTerm}
                className="database-page-search-bar"
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            {/* Filters */}
            <div className="database-page-filters">
                <select value={forceFilter} className="database-page-filter-select" onChange={(e) => setForceFilter(e.target.value)}>
                    <option value="">All Forces</option>
                    <option value="pull">Pull</option>
                    <option value="push">Push</option>
                </select>

                <select value={levelFilter} className="database-page-filter-select" onChange={(e) => setLevelFilter(e.target.value)}>
                    <option value="">All Levels</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advanced</option>
                </select>

                <select value={musclesFilter} className="database-page-filter-select" onChange={(e) => setMusclesFilter(e.target.value)}>
                    <option value="">All Muscle Groups</option>
                    <option value="quadriceps">Quads</option>
                    <option value="biceps">Biceps</option>
                    <option value="lats">Lats</option>
                    <option value="hamstring">Hamstrings</option>
                    <option value="abs">Abdominals</option>

                </select>

                <select value={conditionFilter} className="database-page-filter-select" onChange={(e) => setConditionFilter(e.target.value)}>
                    <option value="">No Conditions</option>
                    <option value="obesity">Obesity</option>
                    <option value="anxiety">Anxiety</option>
                    <option value="depression">Depression</option>
                </select>

                <button className="database-page-filter-button" onClick={handleSearch}>Search</button>
            </div>

            {/* Exercise List */}
            <ul className="database-page-exercise-list">
                {filteredExercises.map(exercise => (
                    <li key={exercise.id} className="database-page-exercise-item">
                        <h3>{exercise.name}</h3>
                        <p>Force: {exercise.force}</p>
                        <p>Level: {exercise.level}</p>
                        <p>Equipment: {exercise.equipment}</p>
                        <p>Primary Muscles: {exercise.primaryMuscles.join(', ')}</p>
                        <p>Category: {exercise.category}</p>
                    </li>
                ))}
            </ul>
        </div>
    )}
</div>

    );
};

export default DatabasePageLoggedInView;
