const conditions = 
[
    {
    "name": "Anxiety",
    "symptoms": ["nervousness", "restlessness", "rapid heart rate", "sweating", "trembling"],
    "medications": [
        {
        "name": "Diazepam",
        "code": "ANX001",
        "side_effects": ["drowsiness", "fatigue", "muscle weakness"]
        },
        {
        "name": "Sertraline",
        "code": "ANX002",
        "side_effects": ["nausea", "insomnia", "dry mouth"]
        }
    ]
    },
    {
    "name": "Arthritis - Osteo and Rheumatoid",
    "symptoms": ["joint pain", "stiffness", "swelling", "reduced range of motion", "fatigue"],
    "medications": [
        {
        "name": "Methotrexate",
        "code": "ARTH001",
        "side_effects": ["nausea", "fatigue", "mouth sores"]
        },
        {
        "name": "Ibuprofen",
        "code": "ARTH002",
        "side_effects": ["stomach pain", "heartburn", "dizziness"]
        }
    ]
    },
    {
    "name": "Asthma",
    "symptoms": ["shortness of breath", "chest tightness", "wheezing", "coughing"],
    "medications": [
        {
        "name": "Albuterol",
        "code": "AST001",
        "side_effects": ["nervousness", "shaking", "headache"]
        },
        {
        "name": "Fluticasone",
        "code": "AST002",
        "side_effects": ["throat irritation", "cough", "headache"]
        }
    ]
    },
    {
    "name": "COPD",
    "symptoms": ["shortness of breath", "chronic cough", "wheezing", "chest tightness", "frequent respiratory infections"],
    "medications": [
        {
        "name": "Tiotropium",
        "code": "COPD001",
        "side_effects": ["dry mouth", "constipation", "blurred vision"]
        },
        {
        "name": "Salmeterol",
        "code": "COPD002",
        "side_effects": ["tremor", "nervousness", "headache"]
        }
    ]
    },
    {
    "name": "Depression",
    "symptoms": ["persistent sadness", "loss of interest", "fatigue", "changes in appetite", "sleep disturbances"],
    "medications": [
        {
        "name": "Fluoxetine",
        "code": "DEP001",
        "side_effects": ["nausea", "dizziness", "insomnia"]
        },
        {
        "name": "Citalopram",
        "code": "DEP002",
        "side_effects": ["dry mouth", "sweating", "tremor"]
        }
    ]
    },
    {
    "name": "Diabetes T1 and T2",
    "symptoms": ["increased thirst", "frequent urination", "extreme hunger", "unexplained weight loss", "fatigue"],
    "medications": [
        {
        "name": "Insulin",
        "code": "DIAB001",
        "side_effects": ["hypoglycemia", "weight gain", "skin rash"]
        },
        {
        "name": "Metformin",
        "code": "DIAB002",
        "side_effects": ["nausea", "diarrhea", "metallic taste"]
        }
    ]
    },
    {
    "name": "Hypercholesterolaemia",
    "symptoms": ["often asymptomatic", "chest pain", "heart attack", "stroke"],
    "medications": [
        {
        "name": "Atorvastatin",
        "code": "HYPCH001",
        "side_effects": ["muscle pain", "nausea", "diarrhea"]
        },
        {
        "name": "Simvastatin",
        "code": "HYPCH002",
        "side_effects": ["headache", "constipation", "abdominal pain"]
        }
    ]
    },
    {
    "name": "Hypertension",
    "symptoms": ["often asymptomatic", "headaches", "shortness of breath", "nosebleeds"],
    "medications": [
        {
        "name": "Lisinopril",
        "code": "HYPER001",
        "side_effects": ["cough", "dizziness", "headache"]
        },
        {
        "name": "Amlodipine",
        "code": "HYPER002",
        "side_effects": ["swelling", "fatigue", "nausea"]
        }
    ]
    },
    {
    "name": "Non-specific lower back pain",
    "symptoms": ["muscle ache", "shooting or stabbing pain", "limited flexibility", "inability to stand up straight"],
    "medications": [
        {
        "name": "Ibuprofen",
        "code": "NSLBP001",
        "side_effects": ["stomach pain", "heartburn", "dizziness"]
        },
        {
        "name": "Naproxen",
        "code": "NSLBP002",
        "side_effects": ["nausea", "heartburn", "headache"]
        }
    ]
    },
    {
    "name": "Obesity",
    "symptoms": ["excess body fat", "breathlessness", "increased sweating", "snoring", "inability to cope with sudden physical activity"],
    "medications": [
        {
        "name": "Orlistat",
        "code": "OBES001",
        "side_effects": ["oily stools", "flatulence", "abdominal pain"]
        },
        {
        "name": "Liraglutide",
        "code": "OBES002",
        "side_effects": ["nausea", "diarrhea", "constipation"]
        }
    ]
    },
    {
    "name": "Osteoporosis",
    "symptoms": ["back pain", "loss of height", "stooped posture", "bone fractures"],
    "medications": [
        {
        "name": "Alendronate",
        "code": "OSTEO001",
        "side_effects": ["abdominal pain", "muscle pain", "constipation"]
        },
        {
        "name": "Risedronate",
        "code": "OSTEO002",
        "side_effects": ["nausea", "joint pain", "headache"]
        }
    ]
    }
]

    export default conditions;
  