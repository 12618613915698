import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { User } from './models/user';
import * as Api from "./network/api";
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import Header from './components/Header';
import Footer from './components/Footer';
import PlansPage from './pages/PlansPage';
import ProfilePage from './pages/ProfilePage';
import BackgroundWrapper from './BackgroundWrapper';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardPage from './pages/DashboardPage';
import BlogPage from './pages/BlogPage';
import FormPage from './pages/FormPage';
import WorkoutBuilderPage from './pages/WorkoutBuilderPage';
import SplashPage from './pages/SplashPage';
import ConnectPage from './pages/ConnectPage';
import StoryPage from './pages/StoryPage';
import ContactPage from './pages/ContactPage';
import DatabasePage from './pages/DatabasePage';

function App() {
    const [loggedInUser, setLoggedInUser] = useState<User | null>(null);

    useEffect(() => {
        async function fetchLoggedInUser() {
            try {
                const user = await Api.getLoggedInUser();
                setLoggedInUser(user);
            } catch (error) {
                console.error(error);
            }
        }
        fetchLoggedInUser();
    }, []);

    return (
        <Router>
            <BackgroundWrapper>
                {loggedInUser ? 
                    <div className="app">
                    <Header user={loggedInUser} onLogoutSuccessful={()=>setLoggedInUser(null)}/>
                    <main className="content">
                        <Routes>
                            {loggedInUser && loggedInUser.info &&
                            <Route path="/" element={<DashboardPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            }
                            {loggedInUser && !loggedInUser.info &&
                            <Route path="/" element={<HomePage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            }
                            {!loggedInUser &&
                            <Route path='/' element={<SplashPage/>}/>
                            }
                            
                            <Route path="/home" element={<HomePage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/dashboard" element={<DashboardPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/blog" element={<BlogPage />} />
                            <Route path="/form" element={<FormPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />
                            }/>

                            {loggedInUser.info && 
                            <Route path="/plans" element={<PlansPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            }
                            {loggedInUser.info && 
                            <Route path="/workoutbuilder" element={<WorkoutBuilderPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            }
                            {loggedInUser.info && 
                            <Route path="/database" element={<DatabasePage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            }
                            {loggedInUser.info && 
                                <Route path="/profile" element={<ProfilePage
                                    loggedInUser={loggedInUser}
                                    updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            }

                            <Route path="/connect" element={<ConnectPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/about" element={<AboutPage />} />
                        </Routes>
                    </main>
                    <Footer />
                </div>
                    :                
                <div className="app">
                    <Header user={loggedInUser} onLogoutSuccessful={()=>setLoggedInUser(null)}/>
                    <main className="content">
                        <Routes>
                            <Route path='/' element={<SplashPage/>}/>

                            <Route path="/home" element={<HomePage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/dashboard" element={<DashboardPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/blog" element={<BlogPage />} />
                            <Route path="/form" element={<FormPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />
                            }/>
                            <Route path="/profile" element={<ProfilePage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/connect" element={<ConnectPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/plans" element={<PlansPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/workoutbuilder" element={<WorkoutBuilderPage
                                loggedInUser={loggedInUser}
                                updateUser={(user: User | null) => setLoggedInUser(user)} />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/story" element={<StoryPage />} />
                            <Route path="/contact" element={<ContactPage />} />

                        </Routes>
                    </main>
                    <Footer />
                </div>
                }

            </BackgroundWrapper>
        </Router>
    );
}

export default App;
