const colorPalette = {
    defaultBlue:'#142865',
    default:'#708090',
    vibrantRed: '#FF5733',
    hotPink: '#FF69B4',
    aqua: '#00FFFF',
    blueViolet: '#8A2BE2',
    gold: '#FFD700',
    dodgerBlue: '#1E90FF',
    softRed: '#E57373',
    lightPink: '#FFB6C1',
    darkTurquoise: '#00CED1',
    mediumPurple: '#9370DB',
    steelBlue: '#2f3b4a',
    orange: '#FFA500',
    greenYellow: '#ADFF2F',
    teal: '#008080',
    crimson: '#DC143C',
    darkOrange: '#FF8C00',
    coral: '#FF7F50',
    lightSeaGreen: '#20B2AA',
    royalBlue: '#4169E1',
    mediumOrchid: '#BA55D3',
    seaGreen: '#2E8B57',
    chocolate: '#D2691E',
    peru: '#CD853F',
    darkKhaki: '#BDB76B',
    mediumSlateBlue: '#7B68EE',
    mediumSeaGreen: '#3CB371',
    tomato: '#FF6347',
    darkSalmon: '#E9967A',
    oliveDrab: '#6B8E23',
    slateGray: '#708090',
    deepRed:'#4a2f3b'
}

export default colorPalette