'use client'
import Image from "next/image";
import { Component } from "react";
import AnimatedBarChart from "./AnimatedBarChart";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from 'chart.js';
import { Activity, Workout } from "../models/plan";
import { getSumTSS, getTSS } from "../utils/TSS";
import { Review, User } from "../models/user";

Chart.register(...registerables);

interface ChartProps {
  weekData: Day[];
  user:User,
  mh?:number,
  mw?:number,
  bgc?:string
}

interface ChartState {
  data: {
    labels: string[];
    datasets: {
      label?: string;
      data: number[];
      backgroundColor?: string;
      borderColor?: string;
      borderWidth?: number;
      type?: string;
      fill?: boolean;
    }[];
  };
  options: {
    animation: {
      duration: number;
    };
    scales: {
      y: {
        type: string;
        beginAtZero: boolean;
      };
      x: {
        ticks: {
          display: boolean;
        };
      };
    };
    maintainAspectRatio: boolean;
    responsive: boolean;
    plugins: {
      legend: {
        display: boolean;
        position: string;
      };
    };
    layout: {
      padding: {
        top: number;
        bottom: number;
        left: number;
        right: number;
      };
    };
  };
}

interface ActivityStep {
  stepName: string;
  stepType: string;
  stepDuration?: number;
  stepDistance?: number;
  stepZone?: number;
  stepIntensity?: number;
  stepReps?: number;
  stepSets?: number;
  stepExercise?: string;
  stepDescription: string;
}

interface Day {
  day: string;
  activities: Array<Activity>;
}

export default class ChartComponent extends Component<ChartProps, ChartState> {
  constructor(props: ChartProps) {
    super(props);

    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Training Stress Score: ",
            data: [],
            backgroundColor: "steelblue",
            borderColor: "steelblue",
            borderWidth: 1,
          },
        ],
      },
      options: {
        animation: {
          duration: 200,
        },
        scales: {
          y: {
            type: 'linear', // specify the scale type
            beginAtZero: true,
          },
          x: {
            ticks: {
              display: true, // hide the x-axis ticks
            },
          },
        },
        maintainAspectRatio: false, // prevent the chart from maintaining aspect ratio
        responsive: true, // make the chart responsive
        plugins: {
          legend: {
            display: false,
            position: 'top', // adjust legend position as needed
          },
        },
        layout: {
          padding: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
          }
        },
      },
    };
  }

  componentDidMount() {
    this.updateChartData();
  }

  componentDidUpdate(prevProps: ChartProps) {
    if (prevProps.weekData !== this.props.weekData) {
      this.updateChartData();
    }
  }

  updateChartData() {
    const { weekData } = this.props;
    const labels = weekData.map(day => day.day);
    let data = weekData.map(day =>
      day.activities.reduce((sum, activity) => sum + getSumTSS(activity, this.props.user), 0)
    );

    this.setState({
      data: {
        labels: labels,
        datasets: [
          {
            label: "Training Stress Score: ",
            data: data,
            backgroundColor: "#2f3b4a",
            borderColor: "#2f3b4a",
            borderWidth: 1,
          },
        ],
      }
    });
  }

  render() {
    return (
      <div style={{ backgroundColor: this.props.bgc, width: this.props.mw ? `${this.props.mw}%`:'100%', height: this.props.mh ?`${this.props.mh}%` :'40%', maxWidth:`${this.props.mw}%`, maxHeight:`${this.props.mh}%`, display:'flex', marginTop:'3vh'}}>
        <AnimatedBarChart data={this.state.data} options={this.state.options} />
      </div>
    );
  }
}
