import { Container } from "react-bootstrap";
import ProfilePageLoggedInView from "../components/ProfilePageLoggedInView";
import { User } from "../models/user";
import ProfilePageLoggedOutView from "../components/ProfilePageLoggedOutView";

interface ProfilePageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const ProfilePage = ({ loggedInUser, updateUser }: ProfilePageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <ProfilePageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <ProfilePageLoggedOutView/>
                }
            </>
        </Container>
    );
}

export default ProfilePage;
