import { Container } from "react-bootstrap";
import DashboardPageLoggedInView from "../components/DashboardPageLoggedInView";
import DashboardPageLoggedOutView from "../components/DashboardPageLoggedOutView";
import { User } from "../models/user";

interface DashboardPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const DashboardPage = ({ loggedInUser, updateUser }: DashboardPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <DashboardPageLoggedInView 
                        user={loggedInUser}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <DashboardPageLoggedOutView 
                        />
                }
            </>
        </Container>
    );
}

export default DashboardPage;