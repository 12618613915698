import * as Api from "../network/api";
import { User } from '../models/user';
import '../styles/FormPage.css';
import '../styles/SessionReview.css'
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Slider } from "@mui/material";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import colorPalette from "../utils/colors";

import conditionsList from "../localdata/conditionswithmedications";

interface Medication{
    name:string,
    code:string,
    side_effects:Array<string>
}

interface Condition{
    name:string,
    symptoms:Array<string>,
    medications:Array<Medication>
}

interface CheckedCondition{ condition: Condition; checked: boolean }

interface CheckedMedication{ medication: Medication; checked: boolean }

interface FormPageLoggedInProps {
    user: User,
    onInfoSet: (user: User) => void,
}

const FormPageLoggedInView = ({user, onInfoSet}:  FormPageLoggedInProps) => {

    const [questionStage, setQuestionStage] = useState<number>(0);

    const [currentUser, setCurrentUser] = useState<User>(user);

    const [height, setHeight] = useState(180);
    const [weight, setWeight] = useState(80);
    const [waist, setWaist] = useState(34);
    // const [conditions, setConditions] = useState([]);
    const [medications, setMedications] = useState([]);
    const [checkedConditions, setCheckedConditions] = useState<Array<CheckedCondition>>(
    conditionsList.map((condition) => ({ condition, checked: false }))
    );  
    const [checkedMedications, setCheckedMedications] = useState<CheckedMedication[]>(
        conditionsList.flatMap(condition => condition.medications.map(medication => ({
          medication,
          checked: false
        }))));
      
    

    const handleCheckboxChange = (name: string) => {
        setCheckedConditions((prevCheckedConditions) =>
          prevCheckedConditions.map((item) =>
            item.condition.name === name ? { ...item, checked: !item.checked } : item
          )
        );
      };

      const handleMedicationCheckboxChange = (code: string) => {
        setCheckedMedications((prevCheckedMedications) =>
            prevCheckedMedications.map((item) =>
            item.medication.code === code ? { ...item, checked: !item.checked } : item
          )
        );
      };
    // State variables to store the answers and form stage
    const [formData, setFormData] = useState({
        heartCondition: '',
        chestPainActivity: '',
        chestPainRest: '',
        dizziness: '',
        boneJointProblem: '',
        bloodPressureMedication: '',
        dob: '',
        address: '',
        signature: '',
    });

    const disclaimerText = "I hereby declare that by signing this form I accept sole responsibility for my health throughout any training plan or coaching undertaken. Training undertaken from this plan is done so solely at my own risk. I declare that I will not train beyond my ability and I understand that any information or advice given does not substitute, replace, or change any professional medical advice, diagnosis, or treatment. If you believe you are injured or require health-related advice, you should contact a health-care professional.I hereby fully and forever release and discharge the online personal trainer (seller), its assigns and agents from all claims, demands, damages, rights of action, present and future therein. I understand and warrant, release and agree that I am in good physical condition and that I have no disability, impairment or ailment preventing me from engaging in active or passive exercise that will be detrimental to overall health, safety, or comfort, or physical condition if I engage or participate (other than those items fully discussed on the health history form). All client information received will be destroyed upon finishing or termination of a training plan or package. Any information stored will be done so securely and safely in line with GDPR guidelines.  Any information given shall not be shared with any 3rd party companies or otherwise. All information given is tailored and private to the purchaser of this plan and by signing this you declare that any information shall not be distributed outside of it’s intended users. I understand that within 14 days of the first month purchase, the client is entitled to a full refund. After this point fees are non-refundable for the month purchased."

    const nextStage = () => setQuestionStage(prevStage => prevStage + 1);
    const prevStage = () => setQuestionStage(prevStage => Math.max(prevStage - 1, 0));


    // Function to handle going back to main view
    const handleBackButtonClick = () => {
        setQuestionStage(0);
    };

    const finishReview = () => {
        setQuestionStage(0);
    }

    async function handleSubmitClick(){
        try {
            const newUser = { ...user };

            // Update
            newUser.info = {
                height:height,
                weight:weight,
                waistCircumference:waist,
                conditions:checkedConditions,
                medications:checkedMedications,
                parq:formData,
                complete:true,
            }
    
            // Update the user data via API
            await Api.updateUser(user._id, newUser);
            onInfoSet(newUser)
            nextStage()
                    
        } catch (error) {
            console.error('Error updating user:', error);
            alert('Failed to save changes');
        }
    }

    const handleChange = (e:any) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const generateMarks = () => {
        const marks = [];
        for (let seconds = 60; seconds <= 540; seconds += 5) {
            marks.push({ value: seconds });
        }
        return marks;
    };

    const handleSubmit = (e:any) => {
        e.preventDefault();
        // Handle form submission, e.g., send data to an API or display it
        console.log(formData);
    };

    const marks = generateMarks();

    const sliderStyling = {
        color: 'primary.main', // or use a hex code like '#1976d2'
        height: 8, // height of the slider track
        width: '60vw',
        '& .MuiSlider-thumb': {
            height: 12, // size of the thumb
            width: 12,
            backgroundColor: '#fff',
            border: '2px solid currentColor',
            '&:hover': {
                boxShadow: '0 0 0 8px rgba(0,0,0,0.16)',
            },
            '&.Mui-active': {
                boxShadow: '0 0 0 14px rgba(0,0,0,0.16)',
            },
        },
        '& .MuiSlider-rail': {
            height: 8,
        },
        '& .MuiSlider-track': {
            height: 8,
        },
    }

    return (
            questionStage === 0 ?
            <div className="home-page" style={{display:'flex', height:'70vh', flex:1,justifyContent:'center', alignItems:'center'}}>
                <h2 style={{fontSize:'3em', color:'black'}} className="main-title">{`Medical Information Form`}</h2>
                    <p style={{color:'black'}}>Use this form to update your medical information</p>
                    <label className="review-button" onClick={()=>setQuestionStage(1)} >Get Started</label>
                </div>
            :
            (
                <body style={{display:'flex', flex:1, justifyContent:'center', alignItems:'center'}}>
                <div 
                style={{
                    backgroundColor: '#ffffff55', 
                    height: '75vh', 
                    width: '90vw', 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start', 
                    borderRadius:'5px',
                    alignItems: 'center',
                    color:'black',
                    paddingTop:'20px'
                }}>

                    {questionStage === 1 && 
                    <div style={{width:'100%'}}>
                        <FontAwesomeIcon className="back-button" style={{marginLeft:'1.5vw'}} onClick={()=>setQuestionStage(0)} icon={faArrowLeft}></FontAwesomeIcon>

                    </div>

                    }
                    <div >
                        {questionStage === 1 && (
                            <div className="form-stage">
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'black'}} className="main-title">Terms and Conditions</h2>
                                
                                <label style={{fontSize:'1vw', textAlign:'center', padding:'30px 20px', backgroundColor:`white`, borderRadius:'5px'}} >{disclaimerText}</label>

                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-next-button" onClick={nextStage}>Accept Conditions</Button>
                                </div>
                            </div>
                        )}
                        {questionStage === 2 && (
                            <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
                                <div style={{width:'auto', display:'flex', justifyContent:'flex-start', flexDirection:'column', height:'70vh'}}>
                                    <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'black'}} className="main-title">Basic Information</h2>

                                    <label className="question-label">What Is Your Height? (cm)</label>
                                    <Slider 
                                        defaultValue={180}
                                        value={height}
                                        onChange={(e: Event, value: number | number[]) => setHeight(value as number)}
                                        step={1} 
                                        min={150} 
                                        max={220}
                                        marks
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    <label className="question-label">What Is Your Weight? (kg)</label>
                                    <Slider
                                        value={weight}
                                        defaultValue={80}
                                        onChange={(e: Event, value: number | number[]) => setWeight(value as number)}
                                        step={1}
                                        min={40}
                                        max={200}
                                        marks={marks}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                    <label className="question-label">What Is Your Waist Circumference? (in)</label>
                                    <Slider
                                        defaultValue={34}
                                        value={waist}
                                        onChange={(e: Event, value: number | number[]) => setWaist(value as number)}
                                        step={1}
                                        min={20}
                                        max={70}
                                        marks={marks}
                                        valueLabelDisplay="auto"
                                        sx={sliderStyling}
                                    />
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}
                        {questionStage === 3 && (

                            <div className="form-stage" style={{display:'flex', flexDirection:'column', overflowY:'scroll'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'black'}} className="parq-title">ParQ Form - Health Questions</h2>
                                <label className="question-label">
                                    Has your doctor ever said you have a heart condition and that you should only do physical activity recommended by a doctor?
                                </label>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                <input className="radio-yes"
                                        type="radio"
                                        name="heartCondition"
                                        value="yes"
                                        checked={formData.heartCondition === 'yes'}
                                        onChange={handleChange}
                                    /> Yes
                                    <input className="radio-no"
                                        type="radio"
                                        name="heartCondition"
                                        value="no"
                                        checked={formData.heartCondition === 'no'}
                                        onChange={handleChange}
                                    /> No
                                    </div>
                                <label className="question-label">
                                    Do you feel pain in your chest when you do physical activity?
                                </label>
                                <div style={{display:'flex', flexDirection:'row'}}>

                                <input className="radio-yes"
                                        type="radio"
                                        name="chestPainActivity"
                                        value="yes"
                                        checked={formData.chestPainActivity === 'yes'}
                                        onChange={handleChange}
                                    /> Yes
                                    <input className="radio-no"
                                        type="radio"
                                        name="chestPainActivity"
                                        value="no"
                                        checked={formData.chestPainActivity === 'no'}
                                        onChange={handleChange}
                                    /> No
                                    </div>
                                <label className="question-label">
                                    In the past month, have you had chest pain when you were not doing physical activity?
                                </label>
                                <div style={{display:'flex', flexDirection:'row'}}>

                                <input className="radio-yes"
                                        type="radio"
                                        name="chestPainRest"
                                        value="yes"
                                        checked={formData.chestPainRest === 'yes'}
                                        onChange={handleChange}
                                    /> Yes
                                    <input className="radio-no"
                                        type="radio"
                                        name="chestPainRest"
                                        value="no"
                                        checked={formData.chestPainRest === 'no'}
                                        onChange={handleChange}
                                    /> No
                                    </div>
                                <label className="question-label">
                                    Do you lose balance because of dizziness or do you ever lose consciousness?
                                </label>
                                <div style={{display:'flex', flexDirection:'row'}}>

                                <input className="radio-yes"
                                        type="radio"
                                        name="dizziness"
                                        value="yes"
                                        checked={formData.dizziness === 'yes'}
                                        onChange={handleChange}
                                    /> Yes
                                    <input className="radio-no"
                                        type="radio"
                                        name="dizziness"
                                        value="no"
                                        checked={formData.dizziness === 'no'}
                                        onChange={handleChange}
                                    /> No
                                    </div>
                                <label className="question-label">
                                    Do you have a bone or joint problem (e.g., back, knee, or hip) that could be made worse by a change in your physical activity?
                                </label>
                                <div style={{display:'flex', flexDirection:'row'}}>

                                <input className="radio-yes"
                                        type="radio"
                                        name="boneJointProblem"
                                        value="yes"
                                        checked={formData.boneJointProblem === 'yes'}
                                        onChange={handleChange}
                                    /> Yes
                                    <input className="radio-no"
                                        type="radio"
                                        name="boneJointProblem"
                                        value="no"
                                        checked={formData.boneJointProblem === 'no'}
                                        onChange={handleChange}
                                    /> No
                                    </div>
                                <label className="question-label">
                                    Is your doctor currently prescribing medication for your blood pressure or heart condition?
                                </label>
                                <div style={{display:'flex', flexDirection:'row'}}>

                                <input className="radio-yes"
                                        type="radio"
                                        name="bloodPressureMedication"
                                        value="yes"
                                        checked={formData.bloodPressureMedication === 'yes'}
                                        onChange={handleChange}
                                    /> Yes
                                    <input className="radio-no"
                                        type="radio"
                                        name="bloodPressureMedication"
                                        value="no"
                                        checked={formData.bloodPressureMedication === 'no'}
                                        onChange={handleChange}
                                    /> No
                                    </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}   
                        {questionStage === 4 && (
                            <div className="form-stage">
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'black'}} className="parq-title">Conditions</h2>  
                                <label style={{marginBottom:'3vh', }}>Which of the following conditions do you suffer from?</label>
                                <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
                                    <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                    {checkedConditions.map((condition:CheckedCondition, index) => (
                                        <div key={index} style={{ width: '33%', display: 'flex', alignItems: 'center'}}>
                                        <Checkbox 
                                            checked={condition.checked}
                                            onChange={() => handleCheckboxChange(condition.condition.name)}
                                            style={{color:'black'}}/>
                                        <label>{condition.condition.name}</label>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                                </div>
                            </div>
                        )}      
                        {questionStage === 5 && (
                            <div className="form-stage">
                            <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'1vh', marginTop:'2vh', color:'black'}} className="parq-title">Medications</h2>  
                            <label style={{marginBottom:'3vh', }}>Which of the following medications are you taking?</label>
                            <div style={{display:'flex', width:'100%', justifyContent:'center'}}>
                                <div style={{ display: 'flex', flexWrap: 'wrap'}}>
                                {checkedMedications.map((medication:CheckedMedication, index) => (
                                        <div key={index} style={{ width: '25%', marginLeft:'2vw', marginRight:'2vw',display: 'flex', alignItems: 'center'}}>
                                        <Checkbox 
                                            checked={medication.checked}
                                            onChange={() => handleMedicationCheckboxChange(medication.medication.code)}
                                            style={{color:'black'}}/>
                                        <label>{medication.medication.name}</label>
                                        </div>
                                ))}
                                </div>
                            </div>
                            <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                <Button className="form-next-button" onClick={nextStage}>Next Step</Button>
                            </div>
                        </div>
                        )}   
                        {questionStage === 6 && (
                            <div className="form-stage">
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'black'}} className="parq-title">ParQ Form - Agreement and Signature</h2>
                                <div style={{display:'flex', flexDirection:'column'}}>
                                <label className="question-label">
                                    Date of Birth (DoB):
                                </label>
                                <input className="question-input"
                                    style={{fontSize:'1.5em', width:'auto'}}
                                    type="date"
                                    name="dob"
                                    value={formData.dob}
                                    onChange={handleChange}
                                />
                                <label className="question-label">
                                    Signature:
                                </label>
                                <input className="question-input"
                                        autoCorrect="false"
                                        autoComplete="off"
                                        type="text"
                                        name="signature"
                                        value={formData.signature}
                                        onChange={handleChange}
                                    />
                                    </div>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={nextStage}>Finalise</Button>
                                </div>
                            </div>
                        )}            
                        {questionStage === 7 && (
                            <div className="form-stage" style={{maxHeight:'70vh', overflowY:'scroll'}}>
                                <h2 style={{fontSize:'2vw', alignSelf:'center', marginBottom:'5vh', marginTop:'2vh', color:'black'}} className="main-title">Review Your Answers Before Submitting</h2>
                                <table style={{width: '60vw', borderCollapse: 'collapse'}}>
                                    <thead>
                                        <tr>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Height</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Weight</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Waist</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Conditions</th>
                                            <th style={{fontSize:'1.25em', padding:'0.75em', borderBottom: '2px solid #000'}}>Medications</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr  key={1}>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{height}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{weight}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>{waist}</td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd', verticalAlign:'top'}}>
                                                <ul>
                                                    {checkedConditions.map((c, index) => (
                                                        c.checked &&
                                                        <li key={index}>{c.condition.name}</li>
                                                    ))}
                                                </ul>
                                            </td>
                                            <td style={{fontSize:'18px', padding:'0.75em', borderBottom: '1px solid #ddd'}}>
                                                <ul>
                                                    {checkedMedications.map((m, index) => (
                                                        m.checked &&
                                                        <li key={index}>{m.medication.name}</li>
                                                    ))}
                                                </ul>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div style={{width:'100%', display:'flex', justifyContent:'space-between', borderRadius:'5px',position:'absolute', bottom:0}}>
                                    <Button className="form-back-button" onClick={prevStage}>Previous</Button>
                                    <Button className="form-next-button" onClick={handleSubmitClick}>Finish</Button>
                                </div>
                            </div>
                        )}
                        {questionStage === 8 && (
                            <div className="form-stage" style={{display:'flex', justifyContent:'center', height:'65vh'}}>
                                <h2 style={{ fontSize: '2.5vw', fontWeight: 'bold', marginBottom:'1vh', color:'black'}}>Submitted!</h2>
                                <h1 style={{paddingBottom:'1.25vh', fontSize:'1vw'}} className="single-line-text">We'll Update Your Info Accordingly</h1>
                                <div>

                                <Link 
                                    to={'/profile'}
                                    className="review-button" style={{padding:'8px 25px', fontSize:'1.25vw', width:'auto'}} onClick={finishReview}>Back To Profile</Link>
                                </div>
                            </div>

                        )}
                    </div>
            </div>
            </body>
            ));

}

export default FormPageLoggedInView;