import { Effort, User } from "../models/user";
import { secondsToTimeString, timeStringToSeconds } from "../utility";

export interface Zone{
    zone: string, 
    hr:Array<number>,
    pace:Array<string> | 'n/a',
    power:number[] | [0,0],
};


function getZones(activityType:string, user:User){
    switch (activityType){
        case 'Run':
            return getRunZones(user);
        case 'Swim':
            return getSwimZones(user);
        case 'Cycle':
            return getCycleZones(user);
        case 'Strength':
            return getRunZones(user);
        default: 
            return getRunZones(user);
    }

}

function getRunZones(user:User){
    const bestEfforts = user.bestEfforts.filter(effort=>effort.activityType==='Run');
    const best5kEffort = bestEfforts.find(effort => effort.id === 0);

    if (best5kEffort){
        const FTP = timeStringToSeconds(best5kEffort.time) * 1.05
        const FTHR = best5kEffort.avgHeartRate * 0.93
        const distance = 5;

        var zones:Array<Zone> = [
            {
                zone:'Zone 1',
                pace:[secondsToTimeString(Math.round(FTP/distance * 1.4)), secondsToTimeString(Math.round(FTP/distance * 1.29))],
                hr:[Math.round(FTHR * 0.75),Math.round(FTHR * 0.85)],
                power:[0,0]
            },
            {
                zone:'Zone 2',
                pace:[secondsToTimeString(Math.round(FTP/distance * 1.29)), secondsToTimeString(Math.round(FTP/distance * 1.14))],
                hr:[Math.round(FTHR * 0.85),Math.round(FTHR * 0.89)],
                power:[0,0]      
            },
            {
                zone:'Zone 3',
                pace:[secondsToTimeString(Math.round(FTP/distance * 1.14)), secondsToTimeString(Math.round(FTP/distance * 1.06))],
                hr:[Math.round(FTHR * 0.89),Math.round(FTHR * 0.94)],
                power:[0,0]
            },
            {
                zone:'Zone 4',
                pace:[secondsToTimeString(Math.round(FTP/distance * 1.06)), secondsToTimeString(Math.round(FTP/distance * 1.01))],
                hr:[Math.round(FTHR * 0.94),Math.round(FTHR * 0.99)],
                power:[0,0]
            },
            {
                zone:'Zone 5',
                pace:[secondsToTimeString(Math.round(FTP/distance * 1.01)), secondsToTimeString(Math.round(FTP/distance * 0.97))],
                hr:[Math.round(FTHR * 0.99),Math.round(FTHR * 1.06)],
                power:[0,0]
            },
            {
                zone:'Zone 6',
                pace:[secondsToTimeString(Math.round(FTP/distance * 0.97)), secondsToTimeString(Math.round(FTP/distance * 0.9))],
                hr:[Math.round(FTHR * 1.06), Math.round(FTHR * 1.2)],
                power:[0,0]
            },
        ]

        return zones.reverse();
    }else{
        return [];
    }
}

function getSwimZones(user: User) {
    // Filter for swimming best efforts
    const bestEfforts = user.bestEfforts.filter((effort: Effort) => effort.activityType === 'Swim');
    // Assuming we are using a specific swim effort to estimate swim pace zones
    const bestEffort = bestEfforts.find(effort => effort.id === 5); // Adjust the ID or condition as needed

    if (bestEffort) {
        const timeInSeconds = timeStringToSeconds(bestEffort.time);
        const distanceInMeters = bestEffort.distance; // Assuming distance is in meters

        // Calculate the pace (seconds per meter)
        const pacePerMeter = timeInSeconds / distanceInMeters;

        // Define pace zones as a percentage of the best effort pace
        const zones: Array<Zone> = [
            {
                zone: 'Zone 1',
                pace: [
                    secondsToTimeString(Math.round(pacePerMeter * 1.4 * distanceInMeters)), 
                    secondsToTimeString(Math.round(pacePerMeter * 1.29 * distanceInMeters))
                ],
                hr: [Math.round(bestEffort.avgHeartRate * 0.75), Math.round(bestEffort.avgHeartRate * 0.85)],
                power: [0, 0]
            },
            {
                zone: 'Zone 2',
                pace: [
                    secondsToTimeString(Math.round(pacePerMeter * 1.29 * distanceInMeters)), 
                    secondsToTimeString(Math.round(pacePerMeter * 1.14 * distanceInMeters))
                ],
                hr: [Math.round(bestEffort.avgHeartRate * 0.85), Math.round(bestEffort.avgHeartRate * 0.89)],
                power: [0, 0]
            },
            {
                zone: 'Zone 3',
                pace: [
                    secondsToTimeString(Math.round(pacePerMeter * 1.14 * distanceInMeters)), 
                    secondsToTimeString(Math.round(pacePerMeter * 1.06 * distanceInMeters))
                ],
                hr: [Math.round(bestEffort.avgHeartRate * 0.89), Math.round(bestEffort.avgHeartRate * 0.94)],
                power: [0, 0]
            },
            {
                zone: 'Zone 4',
                pace: [
                    secondsToTimeString(Math.round(pacePerMeter * 1.06 * distanceInMeters)), 
                    secondsToTimeString(Math.round(pacePerMeter * 1.01 * distanceInMeters))
                ],
                hr: [Math.round(bestEffort.avgHeartRate * 0.94), Math.round(bestEffort.avgHeartRate * 0.99)],
                power: [0, 0]
            },
            {
                zone: 'Zone 5',
                pace: [
                    secondsToTimeString(Math.round(pacePerMeter * 1.01 * distanceInMeters)), 
                    secondsToTimeString(Math.round(pacePerMeter * 0.97 * distanceInMeters))
                ],
                hr: [Math.round(bestEffort.avgHeartRate * 0.99), Math.round(bestEffort.avgHeartRate * 1.06)],
                power: [0, 0]
            },
            {
                zone: 'Zone 6',
                pace: [
                    secondsToTimeString(Math.round(pacePerMeter * 0.97 * distanceInMeters)), 
                    secondsToTimeString(Math.round(pacePerMeter * 0.9 * distanceInMeters))
                ],
                hr: [Math.round(bestEffort.avgHeartRate * 1.06), Math.round(bestEffort.avgHeartRate * 1.2)],
                power: [0, 0]
            },
        ];

        return zones.reverse();
    } else {
        return [];
    }
}


function getCycleZones(user: User) {
    // const bestEfforts = user.bestEfforts.filter(effort=>effort.type==='Cycle');;
    const bestEffort = user.bestEfforts.find(effort => effort.id === 4);
    if (bestEffort) {
        // Assuming FTP is provided directly in the best effort data for cycling, 
        // or calculated similarly to the original method but adapted for cycling.
        const FTP = bestEffort.avgPower; // Assuming bestEffort.power holds FTP
        const FTHR = bestEffort.avgHeartRate; // Directly use the average heart rate

        var zones: Array<Zone> = [
            {
                zone: 'Zone 1',
                power: [Math.round(FTP * 0.55), Math.round(FTP * 0.75)],
                hr: [Math.round(FTHR * 0.65), Math.round(FTHR * 0.75)],
                pace:'n/a'
            },
            {
                zone: 'Zone 2',
                power: [Math.round(FTP * 0.76), Math.round(FTP * 0.89)],
                hr: [Math.round(FTHR * 0.76), Math.round(FTHR * 0.83)],
                pace:'n/a'
            },
            {
                zone: 'Zone 3',
                power: [Math.round(FTP * 0.90), Math.round(FTP * 1.04)],
                hr: [Math.round(FTHR * 0.84), Math.round(FTHR * 0.94)],
                pace:'n/a'
            },
            {
                zone: 'Zone 4',
                power: [Math.round(FTP * 1.05), Math.round(FTP * 1.19)],
                hr: [Math.round(FTHR * 0.95), Math.round(FTHR * 0.99)],
                pace:'n/a'
            },
            {
                zone: 'Zone 5',
                power: [Math.round(FTP * 1.20), Math.round(FTP * 1.49)],
                hr: [Math.round(FTHR * 1.00), Math.round(FTHR * 1.03)],
                pace:'n/a'
            },
            {
                zone: 'Zone 6',
                power: [Math.round(FTP * 1.50), Math.round(FTP * 1.75)],
                hr: [Math.round(FTHR * 1.04), Math.round(FTHR * 1.06)],
                pace:'n/a'
            },
            {
                zone: 'Zone 7',
                power: [Math.round(FTP * 1.76), Math.round(FTP * 2.00)],
                hr: [Math.round(FTHR * 1.07), Math.round(FTHR * 1.10)],
                pace:'n/a'
            }
        ];

        return zones.reverse();
    } else {
        return [];
    }
}


export default getZones