import { Container } from "react-bootstrap";
import WorkoutBuilderPageLoggedInView from "../components/WorkoutBuilderPageLoggedInView";
import WorkoutBuilderPageLoggedOutView from "../components/WorkoutBuilderPageLoggedOutView";
import { User } from "../models/user";

interface WorkoutBuilderPageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const WorkoutBuilderPage = ({ loggedInUser, updateUser }: WorkoutBuilderPageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <WorkoutBuilderPageLoggedInView 
                        user={loggedInUser}
                        onInfoSet={(user:User)=>updateUser(user)}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <WorkoutBuilderPageLoggedOutView 
                        />
                }
            </>
        </Container>
    );
}

export default WorkoutBuilderPage;