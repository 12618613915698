import { Alert, Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import * as Api from "../network/api";
import '../styles/DatabasePage.css'; // Ensure this file has the necessary styles
import Spinner from "./Spinner";
import colorPalette from "../utils/colors";
import { Plan } from "../models/plan";


const DatabasePageLoggedOutView = () => {
    return (
        <div className="plans-page">
            <div>
                <label style={{ fontSize: '2vw', color:'black', marginBottom:'10px'}} className='main-title'>Explore Our Database</label>
                <div style={{ overflowY: 'auto', maxHeight: '65vh', padding:'10px'}}>
                    <ul className="plan-container" style={{ listStyleType: 'none', padding: 0 }}>

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default DatabasePageLoggedOutView;