import { Container } from "react-bootstrap";
import HomePageLoggedInView from "../components/HomePageLoggedInView";
import HomePageLoggedOutView from "../components/HomePageLoggedOutView";
import { User } from "../models/user";

interface HomePageProps {
    loggedInUser: User | null,
    updateUser: (user:User | null) => void
}

const HomePage = ({ loggedInUser, updateUser }: HomePageProps) => {
    return (
        <Container>
            <>
                {loggedInUser
                    ? <HomePageLoggedInView 
                        user={loggedInUser}
                        onInfoSet={(user)=>updateUser(user)}
                        onLogoutSuccessful={()=>updateUser(null)}
                        />
                    : <HomePageLoggedOutView 
                        onSignUpSuccessful={(user) => {
                            updateUser(user);
                        }}
                        onLoginSuccessful={(user) => {
                            updateUser(user);
                        }}
                        />
                }
            </>
        </Container>
    );
}

export default HomePage;