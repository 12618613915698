export function timeStringToSeconds(timeString:string) {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes, seconds] = timeString.split(':').map(Number);

    // Calculate the total number of seconds
    const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;

    return totalSeconds;
}

export function secondsToTimeString(seconds:number) {
    // Calculate hours, minutes, and seconds
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // Pad the values with leading zeros if necessary
    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(remainingSeconds).padStart(2, '0');

    // Construct the time string
    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
}